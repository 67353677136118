import "owl.carousel"

jQuery(document).ready(function ($) {


  // Music Carousel
  $('.carousel').owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplaySpeed: 500, 
    margin: 55,
    items: 1, 
    center: true,
    navText: [
      '<div class="owl-custom-nav-prev owl-custom-nav"><i class="fad fa-chevron-circle-left" aria-label="Previous Slide"></i></div>',
      '<div class="owl-custom-nav-next owl-custom-nav"><i class="fad fa-chevron-circle-right" aria-label="Next Slide"></i></div>'
    ]
  })

  // MOBILE MENU
  $('.hamburger').on('click', function () {
    $(".menu-navigation-container").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.nav-button').on('click', function () {
    if ($('.menu-navigation-container').hasClass('active')) {
      $(".menu-navigation-container").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })
});